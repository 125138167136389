import { useMutation } from "@tanstack/react-query";
import {
  createFinanceSource,
  createPlaidLink,
  Account,
  Institution,
} from "services/finance";
import { usePlaidLink, PlaidLinkOptions } from "react-plaid-link";
import { useEffect, useState } from "react";
import { Button } from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";
import { useAlert } from "providers/alertProvider";
import { useAuth } from "providers/authProvider";
import { queryClient } from "services/api";

const AddNewSource = () => {
  const { user } = useAuth();
  const [token, setToken] = useState<string>(null);
  const { showAlert } = useAlert();
  const createPlaidLinkMutation = useMutation({
    mutationFn: () => createPlaidLink(),
    mutationKey: ["createPlaidLink"],
    onSuccess: ({ link_token }) => {
      setToken(link_token);
    },
  });
  const createFinanceSourceMutation = useMutation({
    mutationFn: ({
      userId,
      publicToken,
      accounts,
      institution,
    }: {
      userId: string;
      publicToken: string;
      accounts: Account[];
      institution: Institution;
    }) => createFinanceSource(userId, publicToken, accounts, institution),
    mutationKey: ["createFinanceSource"],
    onSuccess: () => {
      showAlert(
        "success",
        "Finance account added successfully",
        "We are syncing your data, this may take a few minutes."
      );
      queryClient.invalidateQueries({ queryKey: ["fetchFinanceSources"] });
    },
  });

  const config: PlaidLinkOptions = {
    onSuccess: (public_token, metadata) => {
      createFinanceSourceMutation.mutate({
        userId: user.id,
        publicToken: public_token,
        accounts: metadata.accounts,
        institution: metadata.institution,
      });
    },
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token,
  };

  const { open, exit, ready } = usePlaidLink(config);
  // automatically reinitialize Link
  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return (
    <Button size="sm" onClick={() => createPlaidLinkMutation.mutate()}>
      <BsPlus /> Add finance account
    </Button>
  );
};

export default AddNewSource;
