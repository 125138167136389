import { Api } from "./api";

const fetchRecentEmail = async (id: string) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;

  try {
    const { data } = await axiosInstance.get(`/users/${id}/emails`);
    return data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error; // Propagate the error for handling elsewhere
  }
};

const fetchQueryResults = async (query: string) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;
  if (!query) return null;
  try {
    const { data } = await axiosInstance.get(`/query?query_text=${query}`);
    return data;
  } catch (error) {
    console.error("Error fetching query data:", error);
    // throw error; // Propagate the error for handling elsewhere
  }
};

const createEmailAccount = async (userId: string, authURL: string) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;
  try {
    const { data } = await axiosInstance.post(`/user/${userId}/email_account`, {
      authURL,
    });
    return data;
  } catch (error) {
    console.error("Error creating label:", error);
    throw error;
  }
};

const listEmailAccounts = async (userId: string) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;
  try {
    const { data } = await axiosInstance.get(`/user/${userId}/email_accounts`);
    return data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error; // Propagate the error for handling elsewhere
  }
};

export {
  fetchRecentEmail,
  fetchQueryResults,
  createEmailAccount,
  listEmailAccounts,
};
