import { DataType } from "types/data-types";
import { Api } from "./api";

export type LabelData = {
  name: string;
  keywords: string[];
  type: DataType;
};
const fetchLabels = async (userId: string, type: DataType) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;

  try {
    const { data } = await axiosInstance.get(
      `/users/${userId}/labels?type=${type}`
    );
    return data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error; // Propagate the error for handling elsewhere
  }
};

const createLabel = async (userId: string, labelData: LabelData) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;
  try {
    const { data } = await axiosInstance.post(
      `/users/${userId}/label`,
      labelData
    );
    return data;
  } catch (error) {
    console.error("Error creating label:", error);
    throw error;
  }
};

export { fetchLabels, createLabel };
