import { Api } from "./api";
import { DataType } from "types/data-types";

const fetchGraphData = async ({
  userId,
  componentId,
  start,
  end,
  type,
}: {
  userId: string;
  componentId: string;
  start?: string;
  end?: string;
  type: DataType;
}) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;
  try {
    const { data } = await axiosInstance.get(
      `/users/${userId}/data/${type}/component/${componentId}`,
      {
        params: { start, end },
      }
    );
    return data;
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    throw error;
  }
};

const fetchCountData = async ({
  userId,
  componentId,
  start,
  end,
  type,
}: {
  userId: string;
  componentId: string;
  start?: string;
  end?: string;
  type: DataType;
}) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;
  try {
    const { data } = await axiosInstance.get(
      `/users/${userId}/data/${type}/component/${componentId}`,
      {
        params: { start, end },
      }
    );
    return data;
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    throw error;
  }
};

const fetchTableData = async ({
  userId,
  componentId,
  start,
  end,
  type,
}: {
  userId: string;
  componentId: string;
  start?: string;
  end?: string;
  type: DataType;
}) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;
  try {
    const { data } = await axiosInstance.get(
      `/users/${userId}/data/${type}/component/${componentId}`,
      {
        params: { start, end },
      }
    );
    return data;
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    throw error;
  }
};

export { fetchGraphData, fetchCountData, fetchTableData };
