import React, { useEffect } from "react";
import { formatDistanceToNow } from "date-fns";
import { createColumnHelper } from "@tanstack/react-table";
import { ScrollArea } from "components/scrollArea/scroll-area";
import {
  AccordionItem,
  Accordion,
  AccordionContent,
  AccordionTrigger,
} from "components/accordion/accordion";
import { VscSparkle } from "react-icons/vsc";

import DOMPurify from "dompurify";

type RowObj = {
  sender: string;
  subject: string;
  date: string;
  content: string;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function MailTable(props: {
  title: string;
  tableData: any;
  isLoading: boolean;
}) {
  const { tableData, title, isLoading } = props;
  const [expanded, setExpanded] = React.useState<Record<string, boolean>>({});
  useEffect(() => {
    if (!isLoading) {
      const initialState: Record<string, boolean> = {};
      if (!tableData) return;
      tableData.forEach((item: any) => {
        initialState[item.id] = false;
      });
      setExpanded(initialState);
    }
  }, [tableData, isLoading]);

  return (
    <div>
      {isLoading ? (
        <div className="flex h-56 items-center justify-center">Loading...</div>
      ) : !!tableData && tableData.length === 0 ? (
        <div className="w-full py-4 text-sm font-light italic">
          Emails will be loaded once they are labelled...
        </div>
      ) : (
        <ScrollArea className="h-[40vh] rounded-lg border bg-white px-2 dark:bg-navy-800 dark:text-white">
          <div className="mt-2 flex flex-col gap-2 px-2">
            <Accordion type="multiple" className="w-full">
              {tableData &&
                tableData.map((item: any) => {
                  const utcDate = new Date(`${item.date}Z`);
                  const cleanContent = DOMPurify.sanitize(item.raw_content);
                  const cleanSnippet = DOMPurify.sanitize(item.snippet);
                  return (
                    <AccordionItem value={item.id}>
                      <AccordionTrigger
                        onClick={() =>
                          setExpanded((prevExpanded) => ({
                            ...prevExpanded,
                            [item.id]: !prevExpanded[item.id],
                          }))
                        }
                      >
                        <div className="hover:bg-accent flex w-full flex-col items-start gap-2 rounded-lg p-3 text-left text-sm transition-all">
                          <div className="flex w-full flex-col gap-1">
                            <div className="flex items-center">
                              <div className="flex w-full items-center gap-2">
                                <div className="font-semibold">
                                  {item.sender_name
                                    ? item.sender_name
                                    : item.sender}
                                </div>
                                {!item.read && (
                                  <span className="flex h-2 w-2 rounded-full bg-blue-600" />
                                )}
                              </div>
                              <div className="w-full text-right text-xs">
                                {formatDistanceToNow(utcDate, {
                                  addSuffix: true,
                                })}
                              </div>
                            </div>
                            {!!item.summary ? (
                              <div className="text flex items-center font-medium">
                                <VscSparkle
                                  color="#4318FF"
                                  fill="#4318FF"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginBlock: "4px",
                                    marginRight: "4px",
                                    minHeight: "20px",
                                    minWidth: "20px",
                                  }}
                                />
                                {item.summary}
                              </div>
                            ) : (
                              <>
                                <div className="text-xs font-medium">
                                  {item.subject}
                                </div>
                                {!expanded[item.id] && (
                                  <div className="text-muted-foreground line-clamp-2 text-xs">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: cleanSnippet,
                                      }}
                                    ></div>
                                    {"..."}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="text-muted-foreground h-2vh items-start rounded-lg px-3 text-left">
                        <div
                          dangerouslySetInnerHTML={{ __html: cleanContent }}
                        ></div>
                      </AccordionContent>
                    </AccordionItem>
                  );
                })}
            </Accordion>
          </div>
        </ScrollArea>
      )}
    </div>
  );
}
