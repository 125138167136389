import { Duration } from "types/dashboard-components";
import { fetchTableData } from "services/data";
import { useQuery } from "@tanstack/react-query";
import MailTable from "components/dashWidgets/MailTable";
import BaseWidget from "components/dashWidgets/BaseWidget";
import { DataType } from "types/data-types";
import TransactionsTable from "./TransactionsTable";

const TableWidget = (props: {
  title: string;
  componentId: string; // Changed from dataSourceId to dataSources
  start?: string;
  end?: string;
  id: string;
  userId: string;
  type: DataType;
  removeWidget: (widgetId: string) => void;
}) => {
  const { title, componentId, start, end, id, userId, removeWidget, type } =
    props;
  const { data, error, isLoading } = useQuery({
    queryKey: ["fetchTableData", id],
    queryFn: () => fetchTableData({ userId, componentId, start, end, type }),
  });
  return (
    <BaseWidget title={title} id={id} removeWidget={removeWidget}>
      {type === DataType.EMAIL ? (
        <MailTable title={title} isLoading={isLoading} tableData={data} />
      ) : (
        <TransactionsTable
          userId={userId}
          transactions={data}
          fetchAllTransactions={false}
        />
      )}
    </BaseWidget>
  );
};

export default TableWidget;
