import { QueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { supabase } from './supabase';

const Api = () => {
    const getAccessToken = async function () {
        const { access_token } = (await supabase.auth.getSession()).data.session;
        return access_token || null
    }
    return getAccessToken().then(accessToken => axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    }))

}


const queryClient = new QueryClient();


export { queryClient, Api }