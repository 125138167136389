import { Session, User } from "@supabase/supabase-js";
import { useQuery } from "@tanstack/react-query";
import { createContext, useState, useEffect, useContext } from "react";
import { supabase } from "services/supabase";
import { signInUser } from "services/user";

const AuthContext = createContext({
  user: null,
  supabaseUser: null,
  signIn: null,
  signOut: null,
  session: null,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }: any) => {
  const [supabaseUser, setSupabaseUser] = useState<User | null>(null);
  const [user, setUser] = useState<any>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { data, error, isLoading } = useQuery({
    queryKey: ["sign-in"],
    queryFn: () => signInUser(),
    enabled: !!supabaseUser,
  });

  useEffect(() => {
    const { data: listener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        console.log("Session", session);
        setSession(session);
        setSupabaseUser(session?.user || null);
        setLoading(false);
      }
    );
    return () => {
      listener?.subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    console.log("Data", data);
    if (data && !error && !isLoading && !!supabaseUser) {
      setUser(data);
    }
  }, [data, error, isLoading, supabaseUser]);

  const signIn = async (): Promise<void> => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        skipBrowserRedirect: false,
        redirectTo: `${process.env.REACT_APP_AUTH_REDIRECT}/callback`,
        queryParams: {
          prompt: "consent",
        },
        scopes:
          "profile openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
      },
    });

    if (error) {
      console.error("Error signing in:", error);
    }
  };

  const signOut = async (): Promise<{ error: any }> => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error signing out:", error);
    } else {
      setSupabaseUser(null);
      setUser(null);
      setSession(null);
    }
    return { error };
  };
  return (
    <AuthContext.Provider
      value={{ user, supabaseUser, signIn, signOut, session }}
    >
      {!loading ? children : <div>Loading...</div>}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
