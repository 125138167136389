import { Api } from "./api";

const loginUser = async () => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;

  try {
    const { data } = await axiosInstance.post("/login");
    return data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error; // Propagate the error for handling elsewhere
  }
};

const signInUser = async () => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;

  try {
    const { data } = await axiosInstance.post("/sign-in");
    return data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error; // Propagate the error for handling elsewhere
  }
};
export { loginUser, signInUser };
