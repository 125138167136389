import { useQuery } from "@tanstack/react-query";
import { fetchGraphData } from "services/data";
import { useEffect, useState } from "react";
import BaseWidget from "components/dashWidgets/BaseWidget";
import { DataType } from "types/data-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const GraphWidget = (props: {
  title: string;
  componentId: string; // Changed from dataSourceId to dataSources
  start?: string;
  end?: string;
  id: string;
  userId: string;
  type: DataType;
  removeWidget: (widgetId: string) => void;
}) => {
  const { title, componentId, start, end, id, userId, removeWidget, type } =
    props;
  const { data, error, isLoading } = useQuery({
    queryKey: ["fetchGraphData", id],
    queryFn: () => fetchGraphData({ userId, componentId, start, end, type }), // Updated to use dataSources
  });

  const [chartData, setChartData] = useState<any>([]);
  const [transformedData, setTransformedData] = useState<any>([]);
  const [creditKeys, setCreditKeys] = useState<string[]>([]);
  const [debitKeys, setDebitKeys] = useState<string[]>([]);

  const shadeOfGreen = (index: number) => {
    return `hsl(${120 + index * 10}, 50%, 50%)`;
  };
  const shadeOfColor = (index: number) => {
    return `hsl(${index * 30}, 70%, 50%)`;
  };

  useEffect(() => {
    if (data && !isLoading) {
      setChartData(data);
      const credit = new Set<string>();
      const debit = new Set<string>();
      const transformed = data.map((entry: any) => {
        const transformedEntry = { ...entry };
        for (const key in transformedEntry) {
          if (key !== "date") {
            if (transformedEntry[key] < 0) {
              transformedEntry[key] = Math.abs(transformedEntry[key]);
              credit.add(key);
            } else debit.add(key);
          }
        }

        return transformedEntry;
      });
      setTransformedData(transformed);
      setCreditKeys(Array.from(credit));
      setDebitKeys(Array.from(debit));
    }
  }, [data, isLoading]);
  useEffect(() => {}, [transformedData, creditKeys, debitKeys]);
  return (
    <BaseWidget title={title} id={id} removeWidget={removeWidget}>
      {isLoading ? (
        <p>Loading...</p>
      ) : type === DataType.EMAIL ? (
        chartData.length === 0 ? (
          <div className="w-full py-4 text-sm font-light italic">
            Graph will be loaded once emails are labelled...
          </div>
        ) : (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={chartData}
              margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              {/* Stack uv and amt */}
              <Bar dataKey="count" fill="#4318FF" />;
              {/* Render pvPositive as a separate bar */}
            </BarChart>
          </ResponsiveContainer>
        )
      ) : transformedData.length === 0 ? (
        <div className="w-full py-4 text-sm font-light italic">
          Graph will be loaded once emails are labelled...
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={transformedData}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            {/* Stack uv and amt */}
            {debitKeys.map((key: string, index: number) => (
              <Bar dataKey={key} stackId="a" fill={shadeOfColor(index)} />
            ))}

            {creditKeys.map((key: string, index: number) => (
              <Bar dataKey={key} stackId="b" fill={shadeOfGreen(index)} />
            ))}

            {/* Render pvPositive as a separate bar */}
          </BarChart>
        </ResponsiveContainer>
      )}
    </BaseWidget>
  );
};

export default GraphWidget;
