import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/tabs";
import { useQuery } from "@tanstack/react-query";
import { fetchRecentEmail } from "services/email";
import MailTable from "components/dashWidgets/MailTable";
import EmailAccountSourceTable from "./EmailAccountSourceTable";

const OverviewTable = (props: { userId: string }) => {
  const { userId } = props;
  const { data: recentMail, isLoading: isMailLoading } = useQuery({
    queryKey: ["recentMail"],
    queryFn: () => fetchRecentEmail(userId),
    enabled: !!userId,
  });
  return (
    <div className="rounded-lg bg-white">
      <Tabs defaultValue="emails">
        <TabsList className="border-1 grid w-full grid-cols-2">
          <TabsTrigger value="emails">All Inboxes</TabsTrigger>
          <TabsTrigger value="accounts">Accounts</TabsTrigger>
        </TabsList>
        <TabsContent value="emails">
          <MailTable
            tableData={recentMail}
            isLoading={isMailLoading}
            title="Inbox"
          />
        </TabsContent>
        <TabsContent value="accounts" className="p-2">
          <EmailAccountSourceTable userId={userId} />
        </TabsContent>
      </Tabs>
    </div>
  );
};
export default OverviewTable;
