enum WidgetType {
  COUNT = "COUNT",
  GRAPH = "GRAPH",
  TABLE = "TABLE",
}

enum Duration {
  LAST_24_HOURS = "LAST_24_HOURS",
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_30_DAYS = "LAST_30_DAYS",
}

const DurationString = {
  LAST_24_HOURS: "last 24 hours",
  LAST_7_DAYS: "last 7 days",
  LAST_30_DAYS: "last 30 days",
};

type DashboardWidget = {
  id: string;
  type: WidgetType;
  dataSource: object[];
  title: string;
  duration: Duration;
};

export { WidgetType, Duration, DurationString };
export type { DashboardWidget };
