import { Duration, DurationString } from "types/dashboard-components";
import { fetchCountData } from "services/data";
import { useQuery } from "@tanstack/react-query";
import BaseWidget from "components/dashWidgets/BaseWidget";
import { Badge } from "components/badge/badge";
import { DataType } from "types/data-types";

const CountWidget = (props: {
  title: string;
  componentId: string;
  start?: string;
  end?: string;
  id: string;
  userId: string;
  type: DataType;
  removeWidget: (widgetId: string) => void;
}) => {
  const { title, componentId, start, end, id, userId, removeWidget, type } =
    props;
  const { data, error, isLoading } = useQuery({
    queryKey: ["fetchCountData", id],
    queryFn: () => fetchCountData({ userId, componentId, start, end, type }),
  });
  return (
    <BaseWidget title={title} id={id} removeWidget={removeWidget}>
      <div className="mt-2 flex flex-row px-2">
        <h4 className="text text-center font-medium">{data}</h4>
        <Badge className="mx-2  w-24 justify-center bg-blueSecondary text-white dark:bg-lightPrimary dark:text-navy-800">
          {"between " +
            new Date(start).toLocaleDateString() +
            " and " +
            new Date(end).toLocaleDateString()}
        </Badge>
      </div>
    </BaseWidget>
  );
};

export default CountWidget;
