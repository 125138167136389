import { Api } from "./api";

const fetchEmailAuthLink = async (userId: string) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;

  try {
    const { data } = await axiosInstance.get(
      `/users/${userId}/email_accounts/auth_link`
    );
    return data;
  } catch (error) {
    console.error("Error fetching email authorization link:", error);
    throw error; // Propagate the error for handling elsewhere
  }
};

export { fetchEmailAuthLink };
