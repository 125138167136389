import Card from "components/card";
import { FiX } from "react-icons/fi";

const BaseWidget = (props: {
  title: string;
  id: string;
  removeWidget: (widgetId: string) => void;
  children?: React.ReactNode;
}) => {
  const { title, id, removeWidget, children } = props;

  return (
    <Card extra="pb-7 p-[20px] col-span-4">
      <div className="flex flex-col justify-between">
        <div className="ml-1 flex flex-row pt-2">
          <p className="text-md grow font-medium leading-4 text-gray-800 dark:text-white">
            {title}
          </p>
          <button
            className="justified-end w-4 items-center text-sm"
            aria-label="remove widget"
            onClick={(e) => removeWidget(id)}
          >
            <FiX className="h-4 w-4" />
          </button>
        </div>
        {children}
      </div>
    </Card>
  );
};
export default BaseWidget;
