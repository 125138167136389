import { useQuery } from "@tanstack/react-query";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { listTransactions } from "services/finance";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/table";
import { useEffect, useState } from "react";
interface Transactions {
  amount: number;
  merchant_name: string;
  name: string;
  date: string;
  labels: string[];
  account_name: string;
}

const TransactionsTable = (props: {
  userId: string;
  transactions?: Transactions[];
  fetchAllTransactions: boolean;
}) => {
  const { userId, transactions, fetchAllTransactions } = props;
  const {
    data: queryData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["allTransactions", userId],
    queryFn: () => listTransactions(userId),
    enabled: fetchAllTransactions,
  });

  const [data, setData] = useState<Transactions[] | undefined>(null);

  useEffect(() => {
    if (fetchAllTransactions) {
      setData(queryData);
    } else if (transactions) {
      setData(transactions);
    }
  }, [fetchAllTransactions, transactions, queryData]);

  const columns: ColumnDef<Transactions>[] = [
    { accessorKey: "name", header: "Name" },
    { accessorKey: "merchant_name", header: "Merchant" },
    {
      accessorKey: "amount",
      header: "Amount",
      cell: ({ getValue }) => {
        const amount = getValue() as number;
        const isNegative = amount < 0;
        const formattedAmount = Math.abs(amount).toFixed(2);
        const displayAmount = isNegative
          ? `+$${formattedAmount}`
          : `-$${formattedAmount}`;
        return (
          <span className={isNegative ? "text-green-600" : "text-red-600"}>
            {displayAmount}
          </span>
        );
      },
    },
    { accessorKey: "account_name", header: "Account" },
    {
      accessorKey: "date",
      header: "Date",
      cell: ({ getValue }) => {
        return new Date(getValue() as string).toLocaleDateString();
      },
    },
    {
      accessorKey: "labels",
      header: "Labels",
      cell: ({ getValue }) => {
        const labels = getValue() as string[];
        return (
          <div className="inline-block">
            {labels.map((label) => (
              <div
                key={label}
                className={`${getCategoryColor(
                  label
                )} mb-1 mr-1 inline-block whitespace-nowrap rounded-lg px-2 text-white`}
              >
                {label}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      accessorKey: "pfc_category",
      header: "Category",
      cell: ({ getValue }) => {
        const category = getValue() as string;
        return (
          <div className="inline-block">
            <div
              className={`${getCategoryColor(
                category
              )} whitespace-nowrap rounded-lg px-2 text-white`}
            >
              {category}
            </div>
          </div>
        );
      },
    },
  ];

  const getCategoryColor = (category: string) => {
    const colors = [
      "bg-cyan-600",
      "bg-lime-600",
      "bg-amber-600",
      "bg-red-600",
      "bg-blue-600",
      "bg-green-600",
      "bg-purple-600",
      "bg-indigo-600",
      "bg-orange-600",
    ];
    const hash = category
      .split("")
      .reduce((acc, char) => char.charCodeAt(0) + acc, 0);
    return colors[hash % colors.length];
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <>
      <div className="max-h-96 overflow-auto rounded-md border">
        <Table>
          <TableHeader>
            {!!data &&
              table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
          </TableHeader>
          <TableBody>
            {!isLoading && !!data && table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
export default TransactionsTable;
