import { Button } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import InputField from "components/fields/InputField";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { useAuth } from "providers/authProvider";
import { useState } from "react";
import { FiX } from "react-icons/fi";
import { Form } from "react-router-dom";
import { queryClient } from "services/api";
import { createLabel, LabelData } from "services/label";
import { DataType } from "types/data-types";

interface NewSmartLabelProps {
  type: DataType;
}

export default function NewSmartLabel({ type }: NewSmartLabelProps) {
  const [name, setName] = useState<string>(null);
  const [keywords, setKeywords] = useState<string[]>([]);
  const { user } = useAuth();
  const mutation = useMutation({
    mutationFn: ({
      userId,
      labelData,
    }: {
      userId: string;
      labelData: LabelData;
    }) => createLabel(userId, labelData),
    mutationKey: ["createLabel"],
    onSuccess: () => {
      setName(null);
      setKeywords([]);
      queryClient.invalidateQueries({ queryKey: ["fetchLabels", type] });
      document.dispatchEvent(new MouseEvent("click", { shiftKey: true }));
    },
  });

  const handleKeyDown = (e: any) => {
    if (e.target.id === "keywords") {
      if (e.key === " " && e.target.value.trim() !== "") {
        if (keywords.length < 5) {
          setKeywords([...keywords, e.target.value.trim()]);
          e.target.value = "";
        } else {
          alert("You can only have up to 5 tags.");
        }
      }
    }
  };

  const removeKeyword = (index: number) => {
    const newTags = [...keywords];
    newTags.splice(index, 1);
    setKeywords(newTags);
  };

  const _validateInputs = () => {
    if (name === null) {
      alert("Name is required");
      return false;
    }
    if (keywords.length === 0) {
      alert("Keywords are required");
      return false;
    }
    return true;
  };

  const handleSubmit = (event: any) => {
    if (!_validateInputs()) return;
    event.preventDefault();
    mutation.mutate({
      userId: user.id,
      labelData: { name, keywords, type },
    });
  };

  const getModalContent = () => {
    switch (type) {
      case DataType.EMAIL:
        return {
          title: "Create new email label",
          description: "Create a new smart label for your emails.",
          buttonText: "Create Email Label",
        };
      case DataType.TRANSACTION:
        return {
          title: "Create new finance label",
          description: "Create a new smart label for your finances.",
          buttonText: "Create Finance Label",
        };
      default:
        return {
          title: "Create new label",
          description: "Create a new smart label.",
          buttonText: "Create Label",
        };
    }
  };

  const { title, description, buttonText } = getModalContent();

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">{buttonText}</Button>
      </DialogTrigger>
      <DialogContent className="bg-white p-6 sm:max-w-[500px]">
        <DialogHeader className="mb-4">
          <DialogTitle className="text-2xl font-bold">{title}</DialogTitle>
          <DialogDescription className="text-gray-600">
            {description}{" "}
          </DialogDescription>
        </DialogHeader>
        <Form onKeyDown={handleKeyDown} className="space-y-6">
          <div className="space-y-4">
            <InputField
              id={"labelName"}
              label="Name"
              placeholder="Enter name for label"
              variant="label name"
              type="text"
              extra={"mb-4"}
              setValue={setName}
            />
            <div>
              <label
                htmlFor="keywords"
                className="mb-2 block text-sm font-medium text-gray-700"
              >
                Keywords (up to 5)
              </label>
              <div className="mb-2 flex flex-wrap items-center gap-2">
                {keywords.map((keyword, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-2 rounded-full bg-lightPrimary px-3 py-1 text-sm font-medium dark:bg-navy-500"
                  >
                    {keyword}
                    <button
                      type="button"
                      className="transition-colors hover:text-red-500"
                      onClick={() => removeKeyword(index)}
                    >
                      <FiX className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
              <InputField
                id={"keywords"}
                label="Keywords (upto 5)"
                placeholder="Keywords to describe label"
                variant="keywords"
                type="text"
                extra={"mb-4"}
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              type="submit"
              onClick={(event) => handleSubmit(event)}
              className="w-full"
            >
              Create Label
            </Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
