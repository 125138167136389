import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AuthLayout from "layouts/auth";
import Dashboard from "views/dashboard/default";
import AuthProvider from "providers/authProvider";
import AdminLayout from "layouts/admin";
import Profile from "views/dashboard/profile";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./services/api";
import SearchProvider from "./providers/searchProvider";
import AuthCallback from "views/auth-callback/AuthCallback";
import Finance from "views/dashboard/finance";
import OAuthRedirect from "views/dashboard/finance/oauth_redirect";
import { ChakraProvider } from "@chakra-ui/react";
import { AlertProvider } from "providers/alertProvider";
const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <AdminLayout />,
      children: [
        {
          path: "dashboard",
          element: <AdminLayout />,
          children: [
            // {
            //   path: 'some-protected-path',
            //   element: <SomeProtectedComponent />,
            //   loader: someProtectedLoader,
            // },
          ],
        },
      ],
    },
    {
      path: "/login",
      element: <AuthLayout />,
    },
    { path: "/callback", element: <AuthCallback /> },
    {
      path: "/dashboard",
      element: <AdminLayout />,
      children: [
        {
          path: "email",
          element: <Dashboard />,
        },
        {
          path: "finance",
          element: <Finance />,
          children: [
            {
              path: "oauth_redirect",
              element: <OAuthRedirect />,
            },
          ],
        },
        { path: "search", element: <Dashboard /> },
        { path: "profile", element: <Profile /> },
      ],
    },
  ]);

  return (
    <ChakraProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AlertProvider>
            <SearchProvider>
              <RouterProvider router={router} />
            </SearchProvider>
          </AlertProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
};

export default App;
