import { useAuth } from "providers/authProvider";
import { Navigate } from "react-router-dom";

export default function AuthCallback() {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/dashboard/email" replace />;
  }

  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        Redirecting...
      </div>
    </div>
  );
}
