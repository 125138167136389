import { NewSmartLabel } from "components/newSmartLabel";
import { NewWidget } from "components/newWidget";
import { useAuth } from "providers/authProvider";
import { useEffect, useState } from "react";
import { Drawer } from "vaul";
import OverviewTable from "./components/OverviewTable";
import { DataType } from "types/data-types";
import { fetchDashboard, fetchDashboardList } from "services/dashboard";
import { useQuery } from "@tanstack/react-query";
import { DashboardWidget } from "types/dashboard-components";
import UserDashboard from "./components/UserDashboard";
// The usePlaidLink hook manages Plaid Link creation
// It does not return a destroy function;
// instead, on unmount it automatically destroys the Link instance

const Finance = () => {
  const [components, setComponents] = useState<DashboardWidget[]>(null);
  const [dashboardId, setDashboardId] = useState(null);
  const { user } = useAuth();
  const [userId, setUserId] = useState(null);
  const {
    data: dashboardList,
    error: dashboardListError,
    isLoading: dashboardListLoading,
  } = useQuery({
    queryKey: ["dashboardList", DataType.TRANSACTION],
    queryFn: () => fetchDashboardList(user.id, DataType.TRANSACTION),
    enabled: !!user,
  });

  const {
    data: dashboardData,
    error: dashboardError,
    isLoading: dashboardLoading,
  } = useQuery({
    queryKey: ["dashboardData", DataType.TRANSACTION],
    queryFn: () =>
      fetchDashboard({ userId: user.id, dashboardId: dashboardList[0].id }),
    enabled: !!dashboardList && !!user,
  });

  useEffect(() => {
    if (dashboardList && dashboardData) {
      setDashboardId(dashboardList[0].id);
      setComponents(dashboardData.components);
    }
  }, [dashboardList, dashboardData]);

  useEffect(() => {
    if (user) {
      setUserId(user.id);
    }
  }, [user]);

  return (
    <Drawer.Root direction="right" modal={false}>
      {/* Card widget */}
      <div className="flex flex-col">
        <div className="flex flex-row-reverse gap-2 justify-self-start py-5">
          <div className="demo__widget justify-self-end">
            <NewWidget
              dashboardId={dashboardId}
              widgets={components}
              dataType={DataType.TRANSACTION}
            />
          </div>
          <div className="demo__smart_label justify-self-end ">
            <NewSmartLabel type={DataType.TRANSACTION} />
          </div>
        </div>
        {dashboardLoading && !!userId ? (
          <div>Loading...</div>
        ) : (
          <OverviewTable userId={userId} />
        )}
      </div>
      <div className="mt-5">
        {!!userId && (
          <UserDashboard
            userId={userId}
            widgets={components}
            dashboardId={dashboardId}
          />
        )}
      </div>
    </Drawer.Root>
  );
};

export default Finance;
