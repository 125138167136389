import { useQuery } from "@tanstack/react-query";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/table";
import { listFinanceSources } from "services/finance";
import AddNewSource from "./AddNewSource";

interface FinanceSource {
  account_name: string;
  institution_name: string;
  created_at: string;
}

const FinanceSourceTable = (props: { userId: string }) => {
  const { userId } = props;
  const { data, error, isLoading } = useQuery({
    queryKey: ["fetchFinanceSources", userId],
    queryFn: () => listFinanceSources(userId),
  });
  const columns: ColumnDef<FinanceSource>[] = [
    {
      accessorKey: "account_name",
      header: "Name",
    },
    { accessorKey: "institution_name", header: "Institution" },
    {
      accessorKey: "created_at",
      header: "Created At",
      cell: ({ getValue }) => {
        return new Date(getValue() as string).toLocaleDateString();
      },
    },
  ];
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="flex w-full justify-end py-2">
        <AddNewSource />
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {!isLoading && table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
export default FinanceSourceTable;
