import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/tabs";
import FinanceSourceTable from "./FinanceSourceTable";
import TransactionsTable from "components/dashWidgets/TransactionsTable";

const OverviewTable = (props: { userId: string }) => {
  const { userId } = props;
  return (
    <div className="rounded-lg bg-white">
      <Tabs defaultValue="transactions">
        <TabsList className="border-1 grid w-full grid-cols-2">
          <TabsTrigger value="transactions">All Transactions</TabsTrigger>
          <TabsTrigger value="accounts">Accounts</TabsTrigger>
        </TabsList>
        <TabsContent value="transactions">
          <TransactionsTable userId={userId} fetchAllTransactions={true} />
        </TabsContent>
        <TabsContent value="accounts" className="p-2">
          <FinanceSourceTable userId={userId} />
        </TabsContent>
      </Tabs>
    </div>
  );
};
export default OverviewTable;
