import { createContext, useContext, useEffect, useState } from "react";
import { fetchQueryResults } from "services/email";
import { useQuery } from "@tanstack/react-query";

const SearchContext = createContext({
  searchResults: null,
  search: null,
  setSearch: null,
  isLoading: null,
});

export const useSearch = () => {
  return useContext(SearchContext);
};

const SearchProvider = ({ children }: any) => {
  const [search, setSearch] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { data, error, isLoading } = useQuery({
    queryKey: ["search", search],
    queryFn: () => fetchQueryResults(search) as any,
    enabled: !!search && search.length > 0,
  });

  useEffect(() => {
    if (data && !error && !isLoading) {
      setSearchResults(data);
    }
  }, [data, error, isLoading]);

  return (
    <SearchContext.Provider
      value={{ searchResults, isLoading, search, setSearch }}
    >
      {!loading ? children : `<div>Loading...</div>`}
    </SearchContext.Provider>
  );
};
export default SearchProvider;
