import { useState } from "react";
import { Button } from "@chakra-ui/react";
import { useAuth } from "providers/authProvider";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { FcGoogle } from "react-icons/fc";
import { useQuery } from "@tanstack/react-query";
import { fetchEmailAuthLink } from "services/auth";

const AddNewSource = () => {
  const { user } = useAuth();
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const [widgetType, setWidgetType] = useState(null);
  const [title, setTitle] = useState(null);
  const [groupedOptions, setGroupedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  // automatically reinitialize Link
  const signIn = async () => {
    const data = await fetchEmailAuthLink(user?.id);
    if (data) {
      window.open(data, "_blank");
    } else {
      console.error("No authorization link found in the response data.");
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" onClick={() => setIsOpen(true)}>
          Add new email source
        </Button>
      </DialogTrigger>
      <DialogContent className="bg-white p-6 sm:max-w-[500px]">
        <DialogHeader className="mb-4">
          <DialogTitle className="text-2xl font-bold">
            Add new email accounts
          </DialogTitle>
          <DialogDescription className="text-gray-600">
            Add a new widget to your dashboard
          </DialogDescription>
        </DialogHeader>
        <div
          className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800"
          onClick={signIn}
        >
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-md font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewSource;
