import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "providers/authProvider";
import { fetchLabels } from "services/label";
import { listFinanceCategories } from "services/finance";
import Select from "react-select";
import { useEffect, useState } from "react";
import InputField from "components/fields/InputField";
import {
  DashboardWidget,
  WidgetType,
  Duration,
} from "types/dashboard-components";
import { updateDashboard } from "services/dashboard";
import { v4 as uuidv4 } from "uuid";
import { queryClient } from "services/api";
import { Button } from "@chakra-ui/react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { DataType } from "types/data-types";

const widgetTypes = [
  { value: WidgetType.COUNT, label: "Count" },
  { value: WidgetType.GRAPH, label: "Graph" },
  { value: WidgetType.TABLE, label: "Table" },
];

export default function NewWidget({
  dashboardId,
  widgets,
  dataType,
}: {
  dashboardId: string;
  widgets: DashboardWidget[];
  dataType: DataType;
}) {
  const { user } = useAuth();
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const [widgetType, setWidgetType] = useState(null);
  const [title, setTitle] = useState(null);
  const [groupedOptions, setGroupedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const {
    data: labels,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["fetchLabels", dataType],
    queryFn: () => fetchLabels(user?.id, dataType),
    enabled: !!user,
  });

  const {
    data: categories,
    error: categoryError,
    isLoading: isCategoryLoading,
  } = useQuery({
    queryKey: ["fetchTransactionCategories"],
    queryFn: () => listFinanceCategories(user?.id),
    enabled: !!user && dataType === DataType.TRANSACTION,
  });

  useEffect(() => {
    if (categories) {
      const categoryOptions = categories.map((item: any) => ({
        value: item,
        label: item,
      }));
      setGroupedOptions([
        { label: "Categories", options: categoryOptions },
        ...groupedOptions,
      ]);
    }
  }, [categories]);

  useEffect(() => {
    if (labels) {
      const labelOptions = labels.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setGroupedOptions([
        { label: "Labels", options: labelOptions },
        ...groupedOptions,
      ]);
    }
  }, [labels]);

  const mutation = useMutation({
    mutationFn: ({
      userId,
      dashboardId,
      component,
    }: {
      userId: string;
      dashboardId: string;
      component: DashboardWidget;
    }) => updateDashboard(userId, dashboardId, [...widgets, component]),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["dashboardData", dataType] });
      setIsOpen(false); // Close the modal on success
    },
  });

  const _validateInputs = () => {
    if (title === null) {
      alert("Title is required");
      return false;
    }
    if (selectedDataSource === 0) {
      alert("Data source is required");
      return false;
    }
    if (widgetType === 0) {
      alert("Widget type is required");
      return false;
    }
    return true;
  };
  const handleSubmit = (event: any) => {
    if (!_validateInputs()) return;
    event.preventDefault();
    console.log(selectedDataSource);
    mutation.mutate({
      userId: user.id,
      dashboardId,
      component: {
        id: uuidv4(),
        title,
        dataSource: selectedDataSource,
        type: widgetType,
        duration: Duration.LAST_7_DAYS,
      },
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" onClick={() => setIsOpen(true)}>
          Create new widget
        </Button>
      </DialogTrigger>
      <DialogContent className="bg-white p-6 sm:max-w-[500px]">
        <DialogHeader className="mb-4">
          <DialogTitle className="text-2xl font-bold">
            Create new widget
          </DialogTitle>
          <DialogDescription className="text-gray-600">
            Add a new widget to your dashboard
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-4">
            <InputField
              id="widgetTitle"
              label="Widget Title"
              placeholder="Enter widget title"
              variant="widget title"
              type="text"
              extra="mb-4"
              setValue={setTitle}
            />
            <div>
              <label className="mb-2 block text-sm font-medium text-gray-700">
                Data Source
              </label>
              <Select
                className="rounded-xl border"
                placeholder="Select a data source"
                isLoading={isLoading}
                isMulti
                onChange={(e: any) => {
                  setSelectedDataSource(
                    e.map((i: any) => {
                      const type = labels.some(
                        (label: any) => label.id === i.value
                      )
                        ? "label"
                        : "category";
                      return { type, values: i.value };
                    })
                  );
                }}
                options={groupedOptions}
              />
            </div>
            <div>
              <label className="mb-2 block text-sm font-medium text-gray-700">
                Widget Type
              </label>
              <Select
                className="rounded-xl border"
                placeholder="Select a widget type"
                isLoading={isLoading}
                onChange={(e) => setWidgetType(e.value)}
                options={widgetTypes}
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              type="submit"
              className="w-full bg-brand-500 text-white hover:bg-brand-600"
            >
              Create Widget
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
