// Admin Imports
import MainDashboard from "views/dashboard/default";

// Auth Imports

// Icon Imports
import Finance from "views/dashboard/finance";
import { BsInbox } from "react-icons/bs";
import { FiDollarSign } from "react-icons/fi";

const routes = [
  {
    name: "Inbox",
    layout: "/dashboard",
    path: "email",
    icon: <BsInbox className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Finance",
    layout: "/dashboard",
    path: "finance",
    icon: <FiDollarSign className="h-6 w-6" />,
    component: <Finance />,
  },
  // {
  //   name: "Searches",
  //   layout: "/dashboard",
  //   path: "search",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <MainDashboard />,
  // },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/dashboard",
  //   path: "nft-marketplace",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/dashboard",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "data-tables",
  //   component: <DataTables />,
  // },
  // {
  //   name: "Profile",
  //   layout: "/dashboard",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
];
export default routes;
