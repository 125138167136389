import { DashboardWidget, WidgetType } from "types/dashboard-components";
import GraphWidget from "components/dashWidgets/GraphWidget";
import CountWidget from "components/dashWidgets/CountWidget";
import TableWidget from "components/dashWidgets/TableWidget";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { updateDashboard } from "services/dashboard";
import { queryClient } from "services/api";
import { DataType } from "types/data-types";

export default function UserDashboard(props: {
  widgets: DashboardWidget[];
  userId: string;
  dashboardId: string;
}) {
  const { userId, dashboardId, widgets } = props;
  const [components, setComponents] = useState<DashboardWidget[]>(widgets);

  useEffect(() => {
    setComponents(widgets);
  }, [widgets]);

  const mutation = useMutation({
    mutationFn: ({
      userId,
      dashboardId,
      component,
    }: {
      userId: string;
      dashboardId: string;
      component: DashboardWidget[];
    }) => updateDashboard(userId, dashboardId, components),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["dashboardData"] }),
  });

  const removeWidget = (widgetId: string) => {
    const updatedWidgets = widgets.filter((widget) => widget.id !== widgetId);
    setComponents(updatedWidgets);
    mutation.mutate({
      userId,
      dashboardId: props.dashboardId,
      component: updatedWidgets,
    });
  };

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
      {components &&
        components.map((widget) => (
          <div key={widget.id}>
            {/* <h3>{widget.title}</h3> */}
            {widget.type === WidgetType.GRAPH && (
              <div className="col-span-2 row-span-1">
                <GraphWidget
                  type={DataType.TRANSACTION}
                  userId={userId}
                  title={widget.title}
                  componentId={widget.id}
                  id={widget.id}
                  removeWidget={removeWidget}
                />
              </div>
            )}
            {widget.type === WidgetType.COUNT && (
              <div className="col-span-1 row-span-1">
                <CountWidget
                  type={DataType.TRANSACTION}
                  userId={userId}
                  title={widget.title}
                  componentId={widget.id}
                  id={widget.id}
                  removeWidget={removeWidget}
                />
              </div>
            )}
            {widget.type === WidgetType.TABLE && (
              <div className="col-span-4 row-span-3">
                <TableWidget
                  type={DataType.TRANSACTION}
                  userId={userId}
                  title={widget.title}
                  componentId={widget.id}
                  id={widget.id}
                  removeWidget={removeWidget}
                />
              </div>
            )}
            {/* {widget.type === WidgetType.TABLE && <MailTable />} */}
          </div>
        ))}
    </div>
  );
}
