import MailTable from "components/dashWidgets/MailTable";
import { useQuery } from "@tanstack/react-query";
import { useSearch } from "providers/searchProvider";
import {
  fetchOverviewData,
  fetchDashboardList,
  fetchDashboard,
} from "services/dashboard";
import { FiX } from "react-icons/fi";
import { NewSmartLabel } from "components/newSmartLabel";
import { NewWidget } from "components/newWidget";
import { useAuth } from "providers/authProvider";
import { useEffect, useState } from "react";
import UserDashboard from "./components/UserDashboard";
import { Drawer } from "vaul";
import { DataType } from "types/data-types";
import OverviewTable from "./components/OverviewTable";

const Dashboard = () => {
  const [components, setComponents] = useState(null);
  const [dashboardId, setDashboardId] = useState(null);
  const { user } = useAuth();
  const [userId, setUserId] = useState(null);

  const {
    searchResults,
    isLoading: isSearchResultsLoading,
    search,
    setSearch,
  } = useSearch();

  const { data: dashboardList } = useQuery({
    queryKey: ["dashboardList", DataType.EMAIL],
    queryFn: () => fetchDashboardList(user.id, DataType.EMAIL),
    enabled: !!user,
  });

  const { data: dashboardData, isLoading: dashboardLoading } = useQuery({
    queryKey: ["dashboardData", DataType.EMAIL],
    queryFn: () =>
      fetchDashboard({ userId: user.id, dashboardId: dashboardList[0].id }),
    enabled: !!dashboardList && !!user,
  });

  useEffect(() => {
    if (dashboardList && dashboardData) {
      setDashboardId(dashboardList[0].id);
      setComponents(dashboardData.components);
    }
  }, [dashboardList, dashboardData]);
  useEffect(() => {
    if (user) {
      setUserId(user.id);
    }
  }, [user]);

  return (
    <Drawer.Root direction="right" modal={false}>
      {/* Card widget */}
      <div className="flex flex-col">
        {search && (
          <div className="mt-5">
            {searchResults && searchResults.length > 0 && (
              <div className="rounded-lg border bg-white p-2 dark:bg-navy-800">
                <div className="flex flex-row items-center justify-between">
                  <div className="px-2 italic">
                    Showing results for: <b>{search}</b>
                  </div>
                  <div className="px-2">
                    <button
                      className="hover:bg-accent h-4 w-4 rounded-full bg-red-500 "
                      onClick={() => setSearch(null)}
                    >
                      <FiX color="white" />
                    </button>
                  </div>
                </div>
                {/* <CardMenu /> */}
                <MailTable
                  tableData={searchResults}
                  isLoading={isSearchResultsLoading}
                  title={`Showing results for: ${search}`}
                />
              </div>
            )}
          </div>
        )}

        <div className="mt-5 flex flex-row-reverse gap-2 justify-self-start">
          <div className="demo__widget justify-self-end">
            <NewWidget
              dashboardId={dashboardId}
              widgets={components}
              dataType={DataType.EMAIL}
            />
          </div>
          <div className="demo__smart_label justify-self-end ">
            <NewSmartLabel type={DataType.EMAIL} />
          </div>
        </div>
      </div>

      {/* Tables & Charts */}
      <div className="demo__inbox mt-5">
        <OverviewTable userId={userId} />
      </div>
      <div className="mt-5">
        {dashboardLoading ? (
          <div>Loading...</div>
        ) : (
          dashboardData && (
            <UserDashboard
              userId={userId}
              widgets={components}
              dashboardId={dashboardId}
            />
          )
        )}

        <div className="flex-1 flex-grow">
          <Drawer.Portal>
            <Drawer.Overlay className="bg-black/40fixed inset-0" />
            <Drawer.Content className="x fixed bottom-0 right-0 flex h-full flex-col rounded-t-[10px] bg-white">
              <div className="mt-80 h-full flex-1 bg-white p-4">
                <div className="mx-auto max-w-md">
                  <Drawer.Title className="mb-4 font-medium">
                    Unstyled drawer for React.
                  </Drawer.Title>
                  <p className="text-zinc-600 mb-2">
                    This component can be used as a replacement for a Dialog on
                    mobile and tablet devices.
                  </p>
                  <p className="text-zinc-600 mb-8">
                    It uses{" "}
                    <a
                      href="https://www.radix-ui.com/docs/primitives/components/dialog"
                      className="underline"
                      target="_blank"
                    >
                      Radix&rsquo;s Dialog primitive
                    </a>{" "}
                    under the hood and is inspired by{" "}
                    <a
                      href="https://twitter.com/devongovett/status/1674470185783402496"
                      className="underline"
                      target="_blank"
                    >
                      this tweet.
                    </a>
                  </p>
                </div>
              </div>
            </Drawer.Content>
          </Drawer.Portal>
        </div>
      </div>
    </Drawer.Root>
  );
};

export default Dashboard;
