import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Progress,
  Box,
} from "@chakra-ui/react";

// Define types for alert state and context
interface AlertState {
  isOpen: boolean;
  status: "info" | "warning" | "success" | "error";
  title: string;
  description: string;
}

interface AlertContextType {
  showAlert: (
    status: AlertState["status"],
    title: string,
    description: string
  ) => void;
}

// Create the context
const AlertContext = createContext<AlertContextType | undefined>(undefined);

// Custom hook to use the alert context
export const useAlert = (): AlertContextType => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};

// Define the AlertProvider component
interface AlertProviderProps {
  children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alertState, setAlertState] = useState<AlertState>({
    isOpen: false,
    status: "info",
    title: "",
    description: "",
  });

  const [progress, setProgress] = useState(100);
  const intervalRef = useRef<number | null>(null); // Ref to store interval ID

  const showAlert = (
    status: AlertState["status"],
    title: string,
    description: string
  ) => {
    setAlertState({ isOpen: true, status, title, description });
    setProgress(100); // Reset progress bar
  };

  const closeAlert = () => {
    setAlertState((prev) => ({ ...prev, isOpen: false }));
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    if (alertState.isOpen) {
      let timer = setInterval(() => {
        setProgress((progress) => {
          if (progress === 0) {
            clearInterval(timer);
            closeAlert();
            return 0;
          } else return progress - 1;
        });
      }, 100);
    }
  }, [alertState.isOpen]);

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {alertState.isOpen && (
        <Box position="absolute" className="px-auto w-full">
          <Alert
            status={alertState.status}
            variant="subtle"
            className="mx-auto max-w-96"
          >
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>{alertState.title}</AlertTitle>
              <AlertDescription>{alertState.description}</AlertDescription>
            </Box>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={closeAlert}
            />
          </Alert>
          <Progress
            value={progress}
            size="xs"
            colorScheme="blue"
            hasStripe
            isAnimated
            maxWidth={"24rem"}
            justifySelf={"center"}
            marginInline={"auto"}
          />
        </Box>
      )}
      {children}
    </AlertContext.Provider>
  );
};
