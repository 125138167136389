import { Api } from "./api";

export type Account = {
  class_type?: string;
  id: string;
  name: string;
  subtype: string;
};

export type Institution = {
  institution_id: string;
  name: string;
};

const createPlaidLink = async () => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;
  try {
    const { data } = await axiosInstance.post(`/create_link_token`);
    return data;
  } catch (error) {
    console.error("Error creating label:", error);
    throw error;
  }
};

const listFinanceSources = async (userId: string) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;

  try {
    const { data } = await axiosInstance.get(`/user/${userId}/finance_sources`);
    return data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error; // Propagate the error for handling elsewhere
  }
};

const listFinanceCategories = async (userId: string) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;
  try {
    const { data } = await axiosInstance.get(
      `/user/${userId}/transactions/categories`
    );
    return data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error; // Propagate the error for handling elsewhere
  }
};

const createFinanceSource = async (
  userId: string,
  publicToken: string,
  accounts: Account[],
  institution: Institution
) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;
  try {
    const { data } = await axiosInstance.post(
      `/user/${userId}/finance_source`,
      {
        public_token: publicToken,
        accounts,
        institution,
      }
    );
    return data;
  } catch (error) {
    console.error("Error creating label:", error);
    throw error;
  }
};

const listTransactions = async (
  userId: string,
  financeSourceIds?: [string],
  labels?: [string]
) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;
  try {
    const { data } = await axiosInstance.get(`/user/${userId}/transactions`, {
      params: { financeSourceIds, labels },
    });
    return data;
  } catch (error) {
    console.error("Error creating label:", error);
    throw error;
  }
};
export {
  createPlaidLink,
  listFinanceSources,
  createFinanceSource,
  listTransactions,
  listFinanceCategories,
};
