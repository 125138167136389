import { DashboardWidget } from "types/dashboard-components";
import { Api } from "./api";
import { DataType } from "types/data-types";

const fetchOverviewData = async () => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;
  try {
    const { data } = await axiosInstance.get("/dashboard");
    return data;
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    throw error;
  }
};

const fetchDashboardList = async (userId: string, type: DataType) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;
  try {
    const { data } = await axiosInstance.get(`/users/${userId}/dashboards`, {
      params: { type },
    });
    return data;
  } catch (error) {
    console.error("Error fetching dashboard list:", error);
    throw error;
  }
};

const fetchDashboard = async ({
  userId,
  dashboardId,
}: {
  userId: string;
  dashboardId: string;
}) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;
  try {
    const { data } = await axiosInstance.get(
      `/users/${userId}/dashboards/${dashboardId}`
    );
    return data;
  } catch (error) {
    console.error("Error fetching dashboard list:", error);
    throw error;
  }
};

const updateDashboard = async (
  userId: string,
  dashboardId: string,
  components: DashboardWidget[]
) => {
  const axiosInstance = await Api();
  if (!axiosInstance) return null;
  try {
    const { data } = await axiosInstance.patch(
      `/users/${userId}/dashboard/${dashboardId}`,
      {
        components,
      }
    );
    return data;
  } catch (error) {
    console.error("Error updating dashboard:", error);
    throw error;
  }
};

export {
  fetchOverviewData,
  fetchDashboardList,
  fetchDashboard,
  updateDashboard,
};
